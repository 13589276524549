const constants = [
	{
		char: "B",
		points: 3,
		type: 0,
	},
	{
		char: "C",
		points: 3,
		type: 0,
	},
	{
		char: "D",
		points: 2,
		type: 0,
	},

	{
		char: "F",
		points: 4,
		type: 0,
	},
	{
		char: "R",
		points: 1,
		type: 0,
	},
	{
		char: "S",
		points: 1,
		type: 0,
	},
	{
		char: "T",
		points: 1,
		type: 0,
	},

	{
		char: "L",
		points: 1,
		type: 0,
	},
	{
		char: "M",
		points: 3,
		type: 0,
	},
	{
		char: "N",
		points: 1,
		type: 0,
	},

	{
		char: "P",
		points: 3,
		type: 0,
	},
	{
		char: "G",
		points: 2,
		type: 0,
	},
	{
		char: "H",
		points: 4,
		type: 0,
	},
	{
		char: "K",
		points: 5,
		type: 0,
	},
	{
		char: "V",
		points: 4,
		type: 0,
	},
	{
		char: "W",
		points: 4,
		type: 0,
	},
	{
		char: "Y",
		points: 4,
		type: 0,
	},
	{
		char: "J",
		points: 8,
		type: 0,
	},
	{
		char: "Q",
		points: 10,
		type: 0,
	},
	{
		char: "X",
		points: 8,
		type: 0,
	},
	{
		char: "Z",
		points: 10,
		type: 0,
	},
];

const vowels = [
	{
		char: "E",
		points: 1,
		type: 0,
	},
	{
		char: "A",
		points: 1,
		type: 0,
	},
	{
		char: "I",
		points: 1,
		type: 0,
	},
	{
		char: "O",
		points: 1,
		type: 0,
	},
	{
		char: "U",
		points: 1,
		type: 0,
	},
];

const words = [
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Furniture",
			},
		],
		date: "2023-11-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Permanent",
			},
		],
		date: "2023-11-07",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Precisely",
			},
		],
		date: "2023-11-08",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Immediate",
			},
		],
		date: "2023-11-09",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Published",
			},
		],
		date: "2023-11-10",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "President",
			},
		],
		date: "2023-11-11",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Treatment",
			},
		],
		date: "2023-11-12",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Technique",
			},
		],
		date: "2023-11-13",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Institute",
			},
		],
		date: "2023-11-14",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Infection",
			},
		],
		date: "2023-11-15",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Brilliant",
			},
		],
		date: "2023-11-16",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Emphasize",
			},
		],
		date: "2023-11-17",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Temporary",
			},
		],
		date: "2023-11-18",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Increases",
			},
		],
		date: "2023-11-19",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Adventure",
			},
		],
		date: "2023-11-20",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Mentioned",
			},
		],
		date: "2023-11-21",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrity",
			},
		],
		date: "2023-11-22",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Advantage",
			},
		],
		date: "2023-11-23",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Continued",
			},
		],
		date: "2023-11-24",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Somewhere",
			},
		],
		date: "2023-11-25",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Supported",
			},
		],
		date: "2023-11-26",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Recommend",
			},
		],
		date: "2023-11-27",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Interview",
			},
		],
		date: "2023-11-28",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Publisher",
			},
		],
		date: "2023-11-29",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Guarantee",
			},
		],
		date: "2023-11-30",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Surprised",
			},
		],
		date: "2023-12-01",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Available",
			},
		],
		date: "2023-12-02",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Defensive",
			},
		],
		date: "2023-12-03",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Resources",
			},
		],
		date: "2023-12-04",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Eliminate",
			},
		],
		date: "2023-12-05",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Offensive",
			},
		],
		date: "2023-12-06",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Colleague",
			},
		],
		date: "2023-12-07",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Ourselves",
			},
		],
		date: "2023-12-08",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Accompany",
			},
		],
		date: "2023-12-09",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Exception",
			},
		],
		date: "2023-12-10",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Operation",
			},
		],
		date: "2023-12-11",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Dependent",
			},
		],
		date: "2023-12-12",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Knowledge",
			},
		],
		date: "2023-12-13",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Dangerous",
			},
		],
		date: "2023-12-14",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Provision",
			},
		],
		date: "2023-12-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Testimony",
			},
		],
		date: "2023-12-16",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Reinforce",
			},
		],
		date: "2023-12-17",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Scientist",
			},
		],
		date: "2023-12-18",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Effective",
			},
		],
		date: "2023-12-19",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Criticism",
			},
		],
		date: "2023-12-20",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Pollution",
			},
		],
		date: "2023-12-21",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Discussed",
			},
		],
		date: "2023-12-22",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Awareness",
			},
		],
		date: "2023-12-23",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Relations",
			},
		],
		date: "2023-12-24",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Regarding",
			},
		],
		date: "2023-12-25",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Reference",
			},
		],
		date: "2023-12-26",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Territory",
			},
		],
		date: "2023-12-27",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Telephone",
			},
		],
		date: "2023-12-28",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Initially",
			},
		],
		date: "2023-12-29",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Assistant",
			},
		],
		date: "2023-12-30",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Efficient",
			},
		],
		date: "2023-12-31",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Countries",
			},
		],
		date: "2024-01-01",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Religious",
			},
		],
		date: "2024-01-02",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Excellent",
			},
		],
		date: "2024-01-03",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Extension",
			},
		],
		date: "2024-01-04",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Otherwise",
			},
		],
		date: "2024-01-05",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Therefore",
			},
		],
		date: "2024-01-06",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Standards",
			},
		],
		date: "2024-01-07",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Apartment",
			},
		],
		date: "2024-01-08",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Evolution",
			},
		],
		date: "2024-01-09",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Including",
			},
		],
		date: "2024-01-10",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Encourage",
			},
		],
		date: "2024-01-11",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Appointed",
			},
		],
		date: "2024-01-12",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Influence",
			},
		],
		date: "2024-01-13",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Construct",
			},
		],
		date: "2024-01-14",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Authority",
			},
		],
		date: "2024-01-15",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Performed",
			},
		],
		date: "2024-01-16",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Political",
			},
		],
		date: "2024-01-17",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Defendant",
			},
		],
		date: "2024-01-18",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Dimension",
			},
		],
		date: "2024-01-19",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Attribute",
			},
		],
		date: "2024-01-20",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Interests",
			},
		],
		date: "2024-01-21",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Concerned",
			},
		],
		date: "2024-01-22",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "September",
			},
		],
		date: "2024-01-23",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Establish",
			},
		],
		date: "2024-01-24",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Community",
			},
		],
		date: "2024-01-25",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Sensitive",
			},
		],
		date: "2024-01-26",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Introduce",
			},
		],
		date: "2024-01-27",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Employees",
			},
		],
		date: "2024-01-28",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Expressed",
			},
		],
		date: "2024-01-29",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Interpret",
			},
		],
		date: "2024-01-30",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
				],
				word: "Framework",
			},
		],
		date: "2024-01-31",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Primarily",
			},
		],
		date: "2024-02-01",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Container",
			},
		],
		date: "2024-02-02",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Represent",
			},
		],
		date: "2024-02-03",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Disappear",
			},
		],
		date: "2024-02-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Principle",
			},
		],
		date: "2024-02-05",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Sometimes",
			},
		],
		date: "2024-02-06",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Beginning",
			},
		],
		date: "2024-02-07",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Desperate",
			},
		],
		date: "2024-02-08",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Structure",
			},
		],
		date: "2024-02-09",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Difficult",
			},
		],
		date: "2024-02-10",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Recognize",
			},
		],
		date: "2024-02-11",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Prominent",
			},
		],
		date: "2024-02-12",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Currently",
			},
		],
		date: "2024-02-13",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Determine",
			},
		],
		date: "2024-02-14",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrate",
			},
		],
		date: "2024-02-15",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Diversity",
			},
		],
		date: "2024-02-16",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Indicated",
			},
		],
		date: "2024-02-17",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Complaint",
			},
		],
		date: "2024-02-18",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Decisions",
			},
		],
		date: "2024-02-19",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Economics",
			},
		],
		date: "2024-02-20",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Translate",
			},
		],
		date: "2024-02-21",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Perfectly",
			},
		],
		date: "2024-02-22",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Spiritual",
			},
		],
		date: "2024-02-23",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Christian",
			},
		],
		date: "2024-02-24",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Equipment",
			},
		],
		date: "2024-02-25",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Something",
			},
		],
		date: "2024-02-26",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Discourse",
			},
		],
		date: "2024-02-27",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Carefully",
			},
		],
		date: "2024-02-28",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "J",
						points: 8,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Objective",
			},
		],
		date: "2024-02-29",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Generally",
			},
		],
		date: "2024-03-01",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Candidate",
			},
		],
		date: "2024-03-02",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Incentive",
			},
		],
		date: "2024-03-03",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Presented",
			},
		],
		date: "2024-03-04",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Materials",
			},
		],
		date: "2024-03-05",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Existence",
			},
		],
		date: "2024-03-06",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Potential",
			},
		],
		date: "2024-03-07",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Consensus",
			},
		],
		date: "2024-03-08",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Essential",
			},
		],
		date: "2024-03-09",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Agreement",
			},
		],
		date: "2024-03-10",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Telescope",
			},
		],
		date: "2024-03-11",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Similarly",
			},
		],
		date: "2024-03-12",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Executive",
			},
		],
		date: "2024-03-13",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Confident",
			},
		],
		date: "2024-03-14",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Basically",
			},
		],
		date: "2024-03-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Passenger",
			},
		],
		date: "2024-03-16",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Challenge",
			},
		],
		date: "2024-03-17",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Boyfriend",
			},
		],
		date: "2024-03-18",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Associate",
			},
		],
		date: "2024-03-19",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Statement",
			},
		],
		date: "2024-03-20",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Cigarette",
			},
		],
		date: "2024-03-21",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Admission",
			},
		],
		date: "2024-03-22",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Commander",
			},
		],
		date: "2024-03-23",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Substance",
			},
		],
		date: "2024-03-24",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Important",
			},
		],
		date: "2024-03-25",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Situation",
			},
		],
		date: "2024-03-26",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Implement",
			},
		],
		date: "2024-03-27",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Inflation",
			},
		],
		date: "2024-03-28",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Described",
			},
		],
		date: "2024-03-29",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Terrorism",
			},
		],
		date: "2024-03-30",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Professor",
			},
		],
		date: "2024-03-31",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Personnel",
			},
		],
		date: "2024-04-01",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Expensive",
			},
		],
		date: "2024-04-02",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Supporter",
			},
		],
		date: "2024-04-03",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Formation",
			},
		],
		date: "2024-04-04",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Calculate",
			},
		],
		date: "2024-04-05",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Functions",
			},
		],
		date: "2024-04-06",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Typically",
			},
		],
		date: "2024-04-07",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Discovery",
			},
		],
		date: "2024-04-08",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Practical",
			},
		],
		date: "2024-04-09",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Frequency",
			},
		],
		date: "2024-04-10",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Emotional",
			},
		],
		date: "2024-04-11",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Connected",
			},
		],
		date: "2024-04-12",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Household",
			},
		],
		date: "2024-04-13",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Tradition",
			},
		],
		date: "2024-04-14",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Highlight",
			},
		],
		date: "2024-04-15",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Developed",
			},
		],
		date: "2024-04-16",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Cognitive",
			},
		],
		date: "2024-04-17",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Secretary",
			},
		],
		date: "2024-04-18",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Counselor",
			},
		],
		date: "2024-04-19",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Gentleman",
			},
		],
		date: "2024-04-20",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Increased",
			},
		],
		date: "2024-04-21",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Extremely",
			},
		],
		date: "2024-04-22",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Afternoon",
			},
		],
		date: "2024-04-23",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Explained",
			},
		],
		date: "2024-04-24",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Recording",
			},
		],
		date: "2024-04-25",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Seriously",
			},
		],
		date: "2024-04-26",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
				],
				word: "Criticize",
			},
		],
		date: "2024-04-27",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Companies",
			},
		],
		date: "2024-04-28",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Movements",
			},
		],
		date: "2024-04-29",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Operating",
			},
		],
		date: "2024-04-30",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Intensity",
			},
		],
		date: "2024-05-01",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Confusion",
			},
		],
		date: "2024-05-02",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Gradually",
			},
		],
		date: "2024-05-03",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Procedure",
			},
		],
		date: "2024-05-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Insurance",
			},
		],
		date: "2024-05-05",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Attention",
			},
		],
		date: "2024-05-06",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Education",
			},
		],
		date: "2024-05-07",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Pregnancy",
			},
		],
		date: "2024-05-08",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Childhood",
			},
		],
		date: "2024-05-09",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Historian",
			},
		],
		date: "2024-05-10",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Remaining",
			},
		],
		date: "2024-05-11",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Americans",
			},
		],
		date: "2024-05-12",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Chocolate",
			},
		],
		date: "2024-05-13",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Secondary",
			},
		],
		date: "2024-05-14",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Cambridge",
			},
		],
		date: "2024-05-15",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Breakfast",
			},
		],
		date: "2024-05-16",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Suggested",
			},
		],
		date: "2024-05-17",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Component",
			},
		],
		date: "2024-05-18",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Following",
			},
		],
		date: "2024-05-19",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Committee",
			},
		],
		date: "2024-05-20",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Technical",
			},
		],
		date: "2024-05-21",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Certainly",
			},
		],
		date: "2024-05-22",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Corporate",
			},
		],
		date: "2024-05-23",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Regularly",
			},
		],
		date: "2024-05-24",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Emergency",
			},
		],
		date: "2024-05-25",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Condition",
			},
		],
		date: "2024-05-26",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Different",
			},
		],
		date: "2024-05-27",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "According",
			},
		],
		date: "2024-05-28",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Contained",
			},
		],
		date: "2024-05-29",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Guideline",
			},
		],
		date: "2024-05-30",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Selection",
			},
		],
		date: "2024-05-31",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Character",
			},
		],
		date: "2024-06-01",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Direction",
			},
		],
		date: "2024-06-02",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Encounter",
			},
		],
		date: "2024-06-03",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Physician",
			},
		],
		date: "2024-06-04",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Elsewhere",
			},
		],
		date: "2024-06-05",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Everybody",
			},
		],
		date: "2024-06-06",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Necessary",
			},
		],
		date: "2024-06-07",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Expansion",
			},
		],
		date: "2024-06-08",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Classroom",
			},
		],
		date: "2024-06-09",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Financial",
			},
		],
		date: "2024-06-10",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Principal",
			},
		],
		date: "2024-06-11",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Economist",
			},
		],
		date: "2024-06-12",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Processes",
			},
		],
		date: "2024-06-13",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Strategic",
			},
		],
		date: "2024-06-14",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Transform",
			},
		],
		date: "2024-06-15",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Stability",
			},
		],
		date: "2024-06-16",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Intention",
			},
		],
		date: "2024-06-17",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Depending",
			},
		],
		date: "2024-06-18",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Reduction",
			},
		],
		date: "2024-06-19",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Questions",
			},
		],
		date: "2024-06-20",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Beautiful",
			},
		],
		date: "2024-06-21",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Extensive",
			},
		],
		date: "2024-06-22",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Obviously",
			},
		],
		date: "2024-06-23",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Architect",
			},
		],
		date: "2024-06-24",
	},

	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Surprised",
			},
		],
		date: "2024-06-25",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Regularly",
			},
		],
		date: "2024-06-26",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Boyfriend",
			},
		],
		date: "2024-06-27",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Spiritual",
			},
		],
		date: "2024-06-28",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Secretary",
			},
		],
		date: "2024-06-29",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Desperate",
			},
		],
		date: "2024-06-30",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Principal",
			},
		],
		date: "2024-07-01",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Provision",
			},
		],
		date: "2024-07-02",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Religious",
			},
		],
		date: "2024-07-03",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Regarding",
			},
		],
		date: "2024-07-04",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Therefore",
			},
		],
		date: "2024-07-05",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Emergency",
			},
		],
		date: "2024-07-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Perfectly",
			},
		],
		date: "2024-07-07",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Published",
			},
		],
		date: "2024-07-08",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Including",
			},
		],
		date: "2024-07-09",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Discussed",
			},
		],
		date: "2024-07-10",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "President",
			},
		],
		date: "2024-07-11",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Existence",
			},
		],
		date: "2024-07-12",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Generally",
			},
		],
		date: "2024-07-13",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
				],
				word: "Expensive",
			},
		],
		date: "2024-07-14",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Permanent",
			},
		],
		date: "2024-07-15",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Influence",
			},
		],
		date: "2024-07-16",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Gradually",
			},
		],
		date: "2024-07-17",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Education",
			},
		],
		date: "2024-07-18",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Seriously",
			},
		],
		date: "2024-07-19",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Companies",
			},
		],
		date: "2024-07-20",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Encourage",
			},
		],
		date: "2024-07-21",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Gentleman",
			},
		],
		date: "2024-07-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Direction",
			},
		],
		date: "2024-07-23",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Publisher",
			},
		],
		date: "2024-07-24",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Community",
			},
		],
		date: "2024-07-25",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Pregnancy",
			},
		],
		date: "2024-07-26",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Recording",
			},
		],
		date: "2024-07-27",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Currently",
			},
		],
		date: "2024-07-28",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Necessary",
			},
		],
		date: "2024-07-29",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
				],
				word: "Expansion",
			},
		],
		date: "2024-07-30",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Scientist",
			},
		],
		date: "2024-07-31",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Household",
			},
		],
		date: "2024-08-01",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Temporary",
			},
		],
		date: "2024-08-02",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Component",
			},
		],
		date: "2024-08-03",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Presented",
			},
		],
		date: "2024-08-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Everybody",
			},
		],
		date: "2024-08-05",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Structure",
			},
		],
		date: "2024-08-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Elsewhere",
			},
		],
		date: "2024-08-07",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Precisely",
			},
		],
		date: "2024-08-08",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Secondary",
			},
		],
		date: "2024-08-09",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Standards",
			},
		],
		date: "2024-08-10",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
				],
				word: "Recognize",
			},
		],
		date: "2024-08-11",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Associate",
			},
		],
		date: "2024-08-12",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Essential",
			},
		],
		date: "2024-08-13",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Counselor",
			},
		],
		date: "2024-08-14",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Beginning",
			},
		],
		date: "2024-08-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Dependent",
			},
		],
		date: "2024-08-16",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Architect",
			},
		],
		date: "2024-08-17",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Interview",
			},
		],
		date: "2024-08-18",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Similarly",
			},
		],
		date: "2024-08-19",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Movements",
			},
		],
		date: "2024-08-20",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Connected",
			},
		],
		date: "2024-08-21",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Supported",
			},
		],
		date: "2024-08-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Confusion",
			},
		],
		date: "2024-08-23",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Defensive",
			},
		],
		date: "2024-08-24",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Challenge",
			},
		],
		date: "2024-08-25",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Statement",
			},
		],
		date: "2024-08-26",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Sensitive",
			},
		],
		date: "2024-08-27",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Mentioned",
			},
		],
		date: "2024-08-28",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Introduce",
			},
		],
		date: "2024-08-29",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "According",
			},
		],
		date: "2024-08-30",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Financial",
			},
		],
		date: "2024-08-31",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Something",
			},
		],
		date: "2024-09-01",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Strategic",
			},
		],
		date: "2024-09-02",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Potential",
			},
		],
		date: "2024-09-03",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Excellent",
			},
		],
		date: "2024-09-04",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Extensive",
			},
		],
		date: "2024-09-05",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Beautiful",
			},
		],
		date: "2024-09-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Condition",
			},
		],
		date: "2024-09-07",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Transform",
			},
		],
		date: "2024-09-08",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Technical",
			},
		],
		date: "2024-09-09",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Intensity",
			},
		],
		date: "2024-09-10",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Establish",
			},
		],
		date: "2024-09-11",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Attention",
			},
		],
		date: "2024-09-12",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Resources",
			},
		],
		date: "2024-09-13",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Infection",
			},
		],
		date: "2024-09-14",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Reinforce",
			},
		],
		date: "2024-09-15",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Processes",
			},
		],
		date: "2024-09-16",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Offensive",
			},
		],
		date: "2024-09-17",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
				],
				word: "Otherwise",
			},
		],
		date: "2024-09-18",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Equipment",
			},
		],
		date: "2024-09-19",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Intention",
			},
		],
		date: "2024-09-20",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Operation",
			},
		],
		date: "2024-09-21",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Calculate",
			},
		],
		date: "2024-09-22",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Tradition",
			},
		],
		date: "2024-09-23",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Contained",
			},
		],
		date: "2024-09-24",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Depending",
			},
		],
		date: "2024-09-25",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Accompany",
			},
		],
		date: "2024-09-26",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Sometimes",
			},
		],
		date: "2024-09-27",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Disappear",
			},
		],
		date: "2024-09-28",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Knowledge",
			},
		],
		date: "2024-09-29",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Functions",
			},
		],
		date: "2024-09-30",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Materials",
			},
		],
		date: "2024-10-01",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Discovery",
			},
		],
		date: "2024-10-02",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Important",
			},
		],
		date: "2024-10-03",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Advantage",
			},
		],
		date: "2024-10-04",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Carefully",
			},
		],
		date: "2024-10-05",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Exception",
			},
		],
		date: "2024-10-06",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Suggested",
			},
		],
		date: "2024-10-07",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Operating",
			},
		],
		date: "2024-10-08",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Consensus",
			},
		],
		date: "2024-10-09",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Container",
			},
		],
		date: "2024-10-10",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Diversity",
			},
		],
		date: "2024-10-11",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Continued",
			},
		],
		date: "2024-10-12",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Authority",
			},
		],
		date: "2024-10-13",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Selection",
			},
		],
		date: "2024-10-14",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Determine",
			},
		],
		date: "2024-10-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Encounter",
			},
		],
		date: "2024-10-16",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Effective",
			},
		],
		date: "2024-10-17",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Breakfast",
			},
		],
		date: "2024-10-18",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Extension",
			},
		],
		date: "2024-10-19",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Indicated",
			},
		],
		date: "2024-10-20",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Typically",
			},
		],
		date: "2024-10-21",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Emotional",
			},
		],
		date: "2024-10-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
				],
				word: "Awareness",
			},
		],
		date: "2024-10-23",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Apartment",
			},
		],
		date: "2024-10-24",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Described",
			},
		],
		date: "2024-10-25",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Represent",
			},
		],
		date: "2024-10-26",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Personnel",
			},
		],
		date: "2024-10-27",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Prominent",
			},
		],
		date: "2024-10-28",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Relations",
			},
		],
		date: "2024-10-29",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Emphasize",
			},
		],
		date: "2024-10-30",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Insurance",
			},
		],
		date: "2024-10-31",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Technique",
			},
		],
		date: "2024-11-01",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Reduction",
			},
		],
		date: "2024-11-02",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Institute",
			},
		],
		date: "2024-11-03",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Economics",
			},
		],
		date: "2024-11-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Incentive",
			},
		],
		date: "2024-11-05",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Dangerous",
			},
		],
		date: "2024-11-06",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Increases",
			},
		],
		date: "2024-11-07",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Initially",
			},
		],
		date: "2024-11-08",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Performed",
			},
		],
		date: "2024-11-09",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Supporter",
			},
		],
		date: "2024-11-10",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Corporate",
			},
		],
		date: "2024-11-11",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
				],
				word: "Executive",
			},
		],
		date: "2024-11-12",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Immediate",
			},
		],
		date: "2024-11-13",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Explained",
			},
		],
		date: "2024-11-14",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Inflation",
			},
		],
		date: "2024-11-15",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Formation",
			},
		],
		date: "2024-11-16",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Construct",
			},
		],
		date: "2024-11-17",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Dimension",
			},
		],
		date: "2024-11-18",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Brilliant",
			},
		],
		date: "2024-11-19",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Professor",
			},
		],
		date: "2024-11-20",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Discourse",
			},
		],
		date: "2024-11-21",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Adventure",
			},
		],
		date: "2024-11-22",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Recommend",
			},
		],
		date: "2024-11-23",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Attribute",
			},
		],
		date: "2024-11-24",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Different",
			},
		],
		date: "2024-11-25",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Evolution",
			},
		],
		date: "2024-11-26",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Terrorism",
			},
		],
		date: "2024-11-27",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Character",
			},
		],
		date: "2024-11-28",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Extremely",
			},
		],
		date: "2024-11-29",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Treatment",
			},
		],
		date: "2024-11-30",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Americans",
			},
		],
		date: "2024-12-01",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Telescope",
			},
		],
		date: "2024-12-02",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Guideline",
			},
		],
		date: "2024-12-03",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Furniture",
			},
		],
		date: "2024-12-04",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Physician",
			},
		],
		date: "2024-12-05",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Practical",
			},
		],
		date: "2024-12-06",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Cambridge",
			},
		],
		date: "2024-12-07",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Historian",
			},
		],
		date: "2024-12-08",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Translate",
			},
		],
		date: "2024-12-09",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Complaint",
			},
		],
		date: "2024-12-10",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Highlight",
			},
		],
		date: "2024-12-11",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "September",
			},
		],
		date: "2024-12-12",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Territory",
			},
		],
		date: "2024-12-13",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Increased",
			},
		],
		date: "2024-12-14",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Decisions",
			},
		],
		date: "2024-12-15",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Guarantee",
			},
		],
		date: "2024-12-16",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Countries",
			},
		],
		date: "2024-12-17",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Classroom",
			},
		],
		date: "2024-12-18",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Somewhere",
			},
		],
		date: "2024-12-19",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Childhood",
			},
		],
		date: "2024-12-20",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Ourselves",
			},
		],
		date: "2024-12-21",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Confident",
			},
		],
		date: "2024-12-22",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Defendant",
			},
		],
		date: "2024-12-23",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Expressed",
			},
		],
		date: "2024-12-24",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Procedure",
			},
		],
		date: "2024-12-25",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Colleague",
			},
		],
		date: "2024-12-26",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Appointed",
			},
		],
		date: "2024-12-27",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Criticism",
			},
		],
		date: "2024-12-28",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Economist",
			},
		],
		date: "2024-12-29",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Concerned",
			},
		],
		date: "2024-12-30",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrity",
			},
		],
		date: "2024-12-31",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Chocolate",
			},
		],
		date: "2025-01-01",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Telephone",
			},
		],
		date: "2025-01-02",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Celebrate",
			},
		],
		date: "2025-01-03",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Assistant",
			},
		],
		date: "2025-01-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Interests",
			},
		],
		date: "2025-01-05",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Reference",
			},
		],
		date: "2025-01-06",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Afternoon",
			},
		],
		date: "2025-01-07",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Admission",
			},
		],
		date: "2025-01-08",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Substance",
			},
		],
		date: "2025-01-09",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Situation",
			},
		],
		date: "2025-01-10",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Difficult",
			},
		],
		date: "2025-01-11",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Candidate",
			},
		],
		date: "2025-01-12",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Certainly",
			},
		],
		date: "2025-01-13",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Employees",
			},
		],
		date: "2025-01-14",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Interpret",
			},
		],
		date: "2025-01-15",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Frequency",
			},
		],
		date: "2025-01-16",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Pollution",
			},
		],
		date: "2025-01-17",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Committee",
			},
		],
		date: "2025-01-18",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Basically",
			},
		],
		date: "2025-01-19",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Testimony",
			},
		],
		date: "2025-01-20",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Following",
			},
		],
		date: "2025-01-21",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Framework",
			},
		],
		date: "2025-01-22",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Developed",
			},
		],
		date: "2025-01-23",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Christian",
			},
		],
		date: "2025-01-24",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Stability",
			},
		],
		date: "2025-01-25",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Agreement",
			},
		],
		date: "2025-01-26",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Political",
			},
		],
		date: "2025-01-27",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Remaining",
			},
		],
		date: "2025-01-28",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Criticize",
			},
		],
		date: "2025-01-29",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Primarily",
			},
		],
		date: "2025-01-30",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Cigarette",
			},
		],
		date: "2025-01-31",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Cognitive",
			},
		],
		date: "2025-02-01",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Eliminate",
			},
		],
		date: "2025-02-02",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Efficient",
			},
		],
		date: "2025-02-03",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Principle",
			},
		],
		date: "2025-02-04",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "J",
						points: 8,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Objective",
			},
		],
		date: "2025-02-05",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Available",
			},
		],
		date: "2025-02-06",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Commander",
			},
		],
		date: "2025-02-07",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Obviously",
			},
		],
		date: "2025-02-08",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Passenger",
			},
		],
		date: "2025-02-09",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Questions",
			},
		],
		date: "2025-02-10",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Implement",
			},
		],
		date: "2025-02-11",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Appointed",
			},
		],
		date: "2025-02-10",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Increased",
			},
		],
		date: "2025-02-11",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Adventure",
			},
		],
		date: "2025-02-12",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Substance",
			},
		],
		date: "2025-02-13",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Contained",
			},
		],
		date: "2025-02-14",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Frequency",
			},
		],
		date: "2025-02-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Excellent",
			},
		],
		date: "2025-02-16",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Movements",
			},
		],
		date: "2025-02-17",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Interests",
			},
		],
		date: "2025-02-18",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Attribute",
			},
		],
		date: "2025-02-19",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Sometimes",
			},
		],
		date: "2025-02-20",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Establish",
			},
		],
		date: "2025-02-21",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Disappear",
			},
		],
		date: "2025-02-22",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Confusion",
			},
		],
		date: "2025-02-23",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Translate",
			},
		],
		date: "2025-02-24",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Reinforce",
			},
		],
		date: "2025-02-25",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
				],
				word: "Breakfast",
			},
		],
		date: "2025-02-26",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Countries",
			},
		],
		date: "2025-02-27",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Expensive",
			},
		],
		date: "2025-02-28",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Statement",
			},
		],
		date: "2025-03-01",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Expressed",
			},
		],
		date: "2025-03-02",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Otherwise",
			},
		],
		date: "2025-03-03",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Following",
			},
		],
		date: "2025-03-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Developed",
			},
		],
		date: "2025-03-05",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Connected",
			},
		],
		date: "2025-03-06",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Reference",
			},
		],
		date: "2025-03-07",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Situation",
			},
		],
		date: "2025-03-08",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Discovery",
			},
		],
		date: "2025-03-09",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Boyfriend",
			},
		],
		date: "2025-03-10",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Regarding",
			},
		],
		date: "2025-03-11",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Christian",
			},
		],
		date: "2025-03-12",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Available",
			},
		],
		date: "2025-03-13",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Currently",
			},
		],
		date: "2025-03-14",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Defensive",
			},
		],
		date: "2025-03-15",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Emotional",
			},
		],
		date: "2025-03-16",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Americans",
			},
		],
		date: "2025-03-17",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Everybody",
			},
		],
		date: "2025-03-18",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Commander",
			},
		],
		date: "2025-03-19",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "J",
						points: 8,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Objective",
			},
		],
		date: "2025-03-20",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Regularly",
			},
		],
		date: "2025-03-21",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Different",
			},
		],
		date: "2025-03-22",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Technical",
			},
		],
		date: "2025-03-23",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Materials",
			},
		],
		date: "2025-03-24",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Cambridge",
			},
		],
		date: "2025-03-25",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Increases",
			},
		],
		date: "2025-03-26",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Religious",
			},
		],
		date: "2025-03-27",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Companies",
			},
		],
		date: "2025-03-28",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "September",
			},
		],
		date: "2025-03-29",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Discourse",
			},
		],
		date: "2025-03-30",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Criticize",
			},
		],
		date: "2025-03-31",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Associate",
			},
		],
		date: "2025-04-01",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Telephone",
			},
		],
		date: "2025-04-02",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Dimension",
			},
		],
		date: "2025-04-03",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Operation",
			},
		],
		date: "2025-04-04",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Scientist",
			},
		],
		date: "2025-04-05",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Defendant",
			},
		],
		date: "2025-04-06",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Telescope",
			},
		],
		date: "2025-04-07",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Generally",
			},
		],
		date: "2025-04-08",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Indicated",
			},
		],
		date: "2025-04-09",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Institute",
			},
		],
		date: "2025-04-10",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Mentioned",
			},
		],
		date: "2025-04-11",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Tradition",
			},
		],
		date: "2025-04-12",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Assistant",
			},
		],
		date: "2025-04-13",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Operating",
			},
		],
		date: "2025-04-14",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Published",
			},
		],
		date: "2025-04-15",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Challenge",
			},
		],
		date: "2025-04-16",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Economics",
			},
		],
		date: "2025-04-17",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Architect",
			},
		],
		date: "2025-04-18",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Decisions",
			},
		],
		date: "2025-04-19",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Gentleman",
			},
		],
		date: "2025-04-20",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Resources",
			},
		],
		date: "2025-04-21",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Desperate",
			},
		],
		date: "2025-04-22",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Personnel",
			},
		],
		date: "2025-04-23",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Intensity",
			},
		],
		date: "2025-04-24",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Admission",
			},
		],
		date: "2025-04-25",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Determine",
			},
		],
		date: "2025-04-26",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Testimony",
			},
		],
		date: "2025-04-27",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Supporter",
			},
		],
		date: "2025-04-28",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Classroom",
			},
		],
		date: "2025-04-29",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Publisher",
			},
		],
		date: "2025-04-30",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Similarly",
			},
		],
		date: "2025-05-01",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Executive",
			},
		],
		date: "2025-05-02",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Selection",
			},
		],
		date: "2025-05-03",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
				],
				word: "Knowledge",
			},
		],
		date: "2025-05-04",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Principle",
			},
		],
		date: "2025-05-05",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Basically",
			},
		],
		date: "2025-05-06",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Guideline",
			},
		],
		date: "2025-05-07",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrity",
			},
		],
		date: "2025-05-08",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Furniture",
			},
		],
		date: "2025-05-09",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Pregnancy",
			},
		],
		date: "2025-05-10",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Intention",
			},
		],
		date: "2025-05-11",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Inflation",
			},
		],
		date: "2025-05-12",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Gradually",
			},
		],
		date: "2025-05-13",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Discussed",
			},
		],
		date: "2025-05-14",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Dependent",
			},
		],
		date: "2025-05-15",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Technique",
			},
		],
		date: "2025-05-16",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Interview",
			},
		],
		date: "2025-05-17",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Represent",
			},
		],
		date: "2025-05-18",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Recommend",
			},
		],
		date: "2025-05-19",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Beginning",
			},
		],
		date: "2025-05-20",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Obviously",
			},
		],
		date: "2025-05-21",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Diversity",
			},
		],
		date: "2025-05-22",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Described",
			},
		],
		date: "2025-05-23",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Explained",
			},
		],
		date: "2025-05-24",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Guarantee",
			},
		],
		date: "2025-05-25",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Relations",
			},
		],
		date: "2025-05-26",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Continued",
			},
		],
		date: "2025-05-27",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Character",
			},
		],
		date: "2025-05-28",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Introduce",
			},
		],
		date: "2025-05-29",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Chocolate",
			},
		],
		date: "2025-05-30",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Something",
			},
		],
		date: "2025-05-31",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Complaint",
			},
		],
		date: "2025-06-01",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Confident",
			},
		],
		date: "2025-06-02",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Secondary",
			},
		],
		date: "2025-06-03",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Formation",
			},
		],
		date: "2025-06-04",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Seriously",
			},
		],
		date: "2025-06-05",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
				],
				word: "Awareness",
			},
		],
		date: "2025-06-06",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Immediate",
			},
		],
		date: "2025-06-07",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Employees",
			},
		],
		date: "2025-06-08",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Efficient",
			},
		],
		date: "2025-06-09",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Candidate",
			},
		],
		date: "2025-06-10",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
				],
				word: "Framework",
			},
		],
		date: "2025-06-11",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Strategic",
			},
		],
		date: "2025-06-12",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Construct",
			},
		],
		date: "2025-06-13",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Carefully",
			},
		],
		date: "2025-06-14",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Evolution",
			},
		],
		date: "2025-06-15",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Typically",
			},
		],
		date: "2025-06-16",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Elsewhere",
			},
		],
		date: "2025-06-17",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Ourselves",
			},
		],
		date: "2025-06-18",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Influence",
			},
		],
		date: "2025-06-19",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Depending",
			},
		],
		date: "2025-06-20",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Dangerous",
			},
		],
		date: "2025-06-21",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Therefore",
			},
		],
		date: "2025-06-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Brilliant",
			},
		],
		date: "2025-06-23",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Difficult",
			},
		],
		date: "2025-06-24",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Essential",
			},
		],
		date: "2025-06-25",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Interpret",
			},
		],
		date: "2025-06-26",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Extremely",
			},
		],
		date: "2025-06-27",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Extensive",
			},
		],
		date: "2025-06-28",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Prominent",
			},
		],
		date: "2025-06-29",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Consensus",
			},
		],
		date: "2025-06-30",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Committee",
			},
		],
		date: "2025-07-01",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Initially",
			},
		],
		date: "2025-07-02",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Emphasize",
			},
		],
		date: "2025-07-03",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "President",
			},
		],
		date: "2025-07-04",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Advantage",
			},
		],
		date: "2025-07-05",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Principal",
			},
		],
		date: "2025-07-06",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Surprised",
			},
		],
		date: "2025-07-07",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Exception",
			},
		],
		date: "2025-07-08",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Professor",
			},
		],
		date: "2025-07-09",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Component",
			},
		],
		date: "2025-07-10",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrate",
			},
		],
		date: "2025-07-11",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Effective",
			},
		],
		date: "2025-07-12",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Existence",
			},
		],
		date: "2025-07-13",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Direction",
			},
		],
		date: "2025-07-14",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Household",
			},
		],
		date: "2025-07-15",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Questions",
			},
		],
		date: "2025-07-16",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Potential",
			},
		],
		date: "2025-07-17",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Agreement",
			},
		],
		date: "2025-07-18",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Transform",
			},
		],
		date: "2025-07-19",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Financial",
			},
		],
		date: "2025-07-20",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Certainly",
			},
		],
		date: "2025-07-21",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Sensitive",
			},
		],
		date: "2025-07-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Terrorism",
			},
		],
		date: "2025-07-23",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Procedure",
			},
		],
		date: "2025-07-24",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Corporate",
			},
		],
		date: "2025-07-25",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Concerned",
			},
		],
		date: "2025-07-26",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Presented",
			},
		],
		date: "2025-07-27",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Standards",
			},
		],
		date: "2025-07-28",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Cigarette",
			},
		],
		date: "2025-07-29",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "According",
			},
		],
		date: "2025-07-30",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Supported",
			},
		],
		date: "2025-07-31",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Physician",
			},
		],
		date: "2025-08-01",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Childhood",
			},
		],
		date: "2025-08-02",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Historian",
			},
		],
		date: "2025-08-03",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Calculate",
			},
		],
		date: "2025-08-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Implement",
			},
		],
		date: "2025-08-05",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Infection",
			},
		],
		date: "2025-08-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Insurance",
			},
		],
		date: "2025-08-07",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Encourage",
			},
		],
		date: "2025-08-08",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Incentive",
			},
		],
		date: "2025-08-09",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Eliminate",
			},
		],
		date: "2025-08-10",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Community",
			},
		],
		date: "2025-08-11",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Attention",
			},
		],
		date: "2025-08-12",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Extension",
			},
		],
		date: "2025-08-13",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Highlight",
			},
		],
		date: "2025-08-14",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Territory",
			},
		],
		date: "2025-08-15",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Precisely",
			},
		],
		date: "2025-08-16",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Colleague",
			},
		],
		date: "2025-08-17",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Offensive",
			},
		],
		date: "2025-08-18",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Somewhere",
			},
		],
		date: "2025-08-19",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Necessary",
			},
		],
		date: "2025-08-20",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Passenger",
			},
		],
		date: "2025-08-21",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Recording",
			},
		],
		date: "2025-08-22",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Structure",
			},
		],
		date: "2025-08-23",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Stability",
			},
		],
		date: "2025-08-24",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Cognitive",
			},
		],
		date: "2025-08-25",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Political",
			},
		],
		date: "2025-08-26",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Primarily",
			},
		],
		date: "2025-08-27",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Criticism",
			},
		],
		date: "2025-08-28",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Accompany",
			},
		],
		date: "2025-08-29",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Expansion",
			},
		],
		date: "2025-08-30",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Processes",
			},
		],
		date: "2025-08-31",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Remaining",
			},
		],
		date: "2025-09-01",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Apartment",
			},
		],
		date: "2025-09-02",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Reduction",
			},
		],
		date: "2025-09-03",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Treatment",
			},
		],
		date: "2025-09-04",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Authority",
			},
		],
		date: "2025-09-05",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Suggested",
			},
		],
		date: "2025-09-06",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Temporary",
			},
		],
		date: "2025-09-07",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Performed",
			},
		],
		date: "2025-09-08",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Beautiful",
			},
		],
		date: "2025-09-09",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Encounter",
			},
		],
		date: "2025-09-10",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Functions",
			},
		],
		date: "2025-09-11",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Perfectly",
			},
		],
		date: "2025-09-12",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Important",
			},
		],
		date: "2025-09-13",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Container",
			},
		],
		date: "2025-09-14",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Equipment",
			},
		],
		date: "2025-09-15",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Economist",
			},
		],
		date: "2025-09-16",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Secretary",
			},
		],
		date: "2025-09-17",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Emergency",
			},
		],
		date: "2025-09-18",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Spiritual",
			},
		],
		date: "2025-09-19",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Afternoon",
			},
		],
		date: "2025-09-20",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Counselor",
			},
		],
		date: "2025-09-21",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Condition",
			},
		],
		date: "2025-09-22",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Including",
			},
		],
		date: "2025-09-23",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Recognize",
			},
		],
		date: "2025-09-24",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Permanent",
			},
		],
		date: "2025-09-25",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Practical",
			},
		],
		date: "2025-09-26",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Pollution",
			},
		],
		date: "2025-09-27",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Provision",
			},
		],
		date: "2025-09-28",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Education",
			},
		],
		date: "2025-09-29",
	},

	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Household",
			},
		],
		date: "2025-09-30",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Generally",
			},
		],
		date: "2025-10-01",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Movements",
			},
		],
		date: "2025-10-02",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Diversity",
			},
		],
		date: "2025-10-03",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Indicated",
			},
		],
		date: "2025-10-04",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Stability",
			},
		],
		date: "2025-10-05",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Published",
			},
		],
		date: "2025-10-06",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Architect",
			},
		],
		date: "2025-10-07",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Reduction",
			},
		],
		date: "2025-10-08",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Operation",
			},
		],
		date: "2025-10-09",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Sensitive",
			},
		],
		date: "2025-10-10",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Publisher",
			},
		],
		date: "2025-10-11",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Admission",
			},
		],
		date: "2025-10-12",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Cambridge",
			},
		],
		date: "2025-10-13",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Encourage",
			},
		],
		date: "2025-10-14",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
				],
				word: "Criticize",
			},
		],
		date: "2025-10-15",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Provision",
			},
		],
		date: "2025-10-16",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Guideline",
			},
		],
		date: "2025-10-17",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Apartment",
			},
		],
		date: "2025-10-18",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Initially",
			},
		],
		date: "2025-10-19",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Furniture",
			},
		],
		date: "2025-10-20",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
				],
				word: "Framework",
			},
		],
		date: "2025-10-21",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Committee",
			},
		],
		date: "2025-10-22",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Americans",
			},
		],
		date: "2025-10-23",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Supported",
			},
		],
		date: "2025-10-24",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Concerned",
			},
		],
		date: "2025-10-25",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Dangerous",
			},
		],
		date: "2025-10-26",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Developed",
			},
		],
		date: "2025-10-27",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Gentleman",
			},
		],
		date: "2025-10-28",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
				],
				word: "Substance",
			},
		],
		date: "2025-10-29",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Primarily",
			},
		],
		date: "2025-10-30",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Boyfriend",
			},
		],
		date: "2025-10-31",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Temporary",
			},
		],
		date: "2025-11-01",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Encounter",
			},
		],
		date: "2025-11-02",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Effective",
			},
		],
		date: "2025-11-03",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Processes",
			},
		],
		date: "2025-11-04",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Existence",
			},
		],
		date: "2025-11-05",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Beautiful",
			},
		],
		date: "2025-11-06",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Regarding",
			},
		],
		date: "2025-11-07",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Offensive",
			},
		],
		date: "2025-11-08",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Criticism",
			},
		],
		date: "2025-11-09",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Recognize",
			},
		],
		date: "2025-11-10",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Adventure",
			},
		],
		date: "2025-11-11",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Permanent",
			},
		],
		date: "2025-11-12",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "J",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Objective",
			},
		],
		date: "2025-11-13",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Somewhere",
			},
		],
		date: "2025-11-14",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Personnel",
			},
		],
		date: "2025-11-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Practical",
			},
		],
		date: "2025-11-16",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Emergency",
			},
		],
		date: "2025-11-17",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Potential",
			},
		],
		date: "2025-11-18",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
				],
				word: "Elsewhere",
			},
		],
		date: "2025-11-19",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Recording",
			},
		],
		date: "2025-11-20",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Calculate",
			},
		],
		date: "2025-11-21",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Performed",
			},
		],
		date: "2025-11-22",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Extremely",
			},
		],
		date: "2025-11-23",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Frequency",
			},
		],
		date: "2025-11-24",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Telescope",
			},
		],
		date: "2025-11-25",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Structure",
			},
		],
		date: "2025-11-26",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Condition",
			},
		],
		date: "2025-11-27",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Relations",
			},
		],
		date: "2025-11-28",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Desperate",
			},
		],
		date: "2025-11-29",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Seriously",
			},
		],
		date: "2025-11-30",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Consensus",
			},
		],
		date: "2025-12-01",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Suggested",
			},
		],
		date: "2025-12-02",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Colleague",
			},
		],
		date: "2025-12-03",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Strategic",
			},
		],
		date: "2025-12-04",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Community",
			},
		],
		date: "2025-12-05",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Discovery",
			},
		],
		date: "2025-12-06",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Defendant",
			},
		],
		date: "2025-12-07",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Therefore",
			},
		],
		date: "2025-12-08",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Depending",
			},
		],
		date: "2025-12-09",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Establish",
			},
		],
		date: "2025-12-10",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Influence",
			},
		],
		date: "2025-12-11",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Presented",
			},
		],
		date: "2025-12-12",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Something",
			},
		],
		date: "2025-12-13",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
				],
				word: "Ourselves",
			},
		],
		date: "2025-12-14",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Institute",
			},
		],
		date: "2025-12-15",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Container",
			},
		],
		date: "2025-12-16",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Basically",
			},
		],
		date: "2025-12-17",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Associate",
			},
		],
		date: "2025-12-18",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Treatment",
			},
		],
		date: "2025-12-19",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Advantage",
			},
		],
		date: "2025-12-20",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Pollution",
			},
		],
		date: "2025-12-21",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Mentioned",
			},
		],
		date: "2025-12-22",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Otherwise",
			},
		],
		date: "2025-12-23",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Political",
			},
		],
		date: "2025-12-24",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Afternoon",
			},
		],
		date: "2025-12-25",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Cigarette",
			},
		],
		date: "2025-12-26",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Complaint",
			},
		],
		date: "2025-12-27",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
				],
				word: "Expensive",
			},
		],
		date: "2025-12-28",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Knowledge",
			},
		],
		date: "2025-12-29",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Economics",
			},
		],
		date: "2025-12-30",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Corporate",
			},
		],
		date: "2025-12-31",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Emotional",
			},
		],
		date: "2026-01-01",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "According",
			},
		],
		date: "2026-01-02",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Immediate",
			},
		],
		date: "2026-01-03",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Procedure",
			},
		],
		date: "2026-01-04",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Eliminate",
			},
		],
		date: "2026-01-05",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Determine",
			},
		],
		date: "2026-01-06",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Scientist",
			},
		],
		date: "2026-01-07",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Celebrate",
			},
		],
		date: "2026-01-08",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Telephone",
			},
		],
		date: "2026-01-09",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Expansion",
			},
		],
		date: "2026-01-10",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Agreement",
			},
		],
		date: "2026-01-11",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Connected",
			},
		],
		date: "2026-01-12",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Essential",
			},
		],
		date: "2026-01-13",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Surprised",
			},
		],
		date: "2026-01-14",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
				],
				word: "Explained",
			},
		],
		date: "2026-01-15",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Principle",
			},
		],
		date: "2026-01-16",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Decisions",
			},
		],
		date: "2026-01-17",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Recommend",
			},
		],
		date: "2026-01-18",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Exception",
			},
		],
		date: "2026-01-19",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Disappear",
			},
		],
		date: "2026-01-20",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Technical",
			},
		],
		date: "2026-01-21",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Similarly",
			},
		],
		date: "2026-01-22",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Introduce",
			},
		],
		date: "2026-01-23",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Appointed",
			},
		],
		date: "2026-01-24",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Reference",
			},
		],
		date: "2026-01-25",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Component",
			},
		],
		date: "2026-01-26",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Translate",
			},
		],
		date: "2026-01-27",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "K",
						points: 5,
						type: 0,
					},
				],
				word: "Breakfast",
			},
		],
		date: "2026-01-28",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Expressed",
			},
		],
		date: "2026-01-29",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
				],
				word: "Employees",
			},
		],
		date: "2026-01-30",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Situation",
			},
		],
		date: "2026-01-31",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Increased",
			},
		],
		date: "2026-02-01",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Incentive",
			},
		],
		date: "2026-02-02",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Important",
			},
		],
		date: "2026-02-03",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Childhood",
			},
		],
		date: "2026-02-04",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Z",
						points: 10,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Emphasize",
			},
		],
		date: "2026-02-05",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Executive",
			},
		],
		date: "2026-02-06",
	},
	{
		RandomNine: [
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Supporter",
			},
		],
		date: "2026-02-07",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Extensive",
			},
		],
		date: "2026-02-08",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Intention",
			},
		],
		date: "2026-02-09",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Perfectly",
			},
		],
		date: "2026-02-10",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Testimony",
			},
		],
		date: "2026-02-11",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Construct",
			},
		],
		date: "2026-02-12",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Efficient",
			},
		],
		date: "2026-02-13",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Dimension",
			},
		],
		date: "2026-02-14",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Inflation",
			},
		],
		date: "2026-02-15",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Counselor",
			},
		],
		date: "2026-02-16",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Described",
			},
		],
		date: "2026-02-17",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Interview",
			},
		],
		date: "2026-02-18",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "President",
			},
		],
		date: "2026-02-19",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Carefully",
			},
		],
		date: "2026-02-20",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Materials",
			},
		],
		date: "2026-02-21",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
				],
				word: "Highlight",
			},
		],
		date: "2026-02-22",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Cognitive",
			},
		],
		date: "2026-02-23",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Character",
			},
		],
		date: "2026-02-24",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Regularly",
			},
		],
		date: "2026-02-25",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Celebrity",
			},
		],
		date: "2026-02-26",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
				],
				word: "Difficult",
			},
		],
		date: "2026-02-27",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Interpret",
			},
		],
		date: "2026-02-28",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Principal",
			},
		],
		date: "2026-03-01",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Obviously",
			},
		],
		date: "2026-03-02",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Available",
			},
		],
		date: "2026-03-03",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Confusion",
			},
		],
		date: "2026-03-04",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Represent",
			},
		],
		date: "2026-03-05",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Everybody",
			},
		],
		date: "2026-03-06",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Tradition",
			},
		],
		date: "2026-03-07",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Infection",
			},
		],
		date: "2026-03-08",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Pregnancy",
			},
		],
		date: "2026-03-09",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Dependent",
			},
		],
		date: "2026-03-10",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Sometimes",
			},
		],
		date: "2026-03-11",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Different",
			},
		],
		date: "2026-03-12",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Prominent",
			},
		],
		date: "2026-03-13",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Extension",
			},
		],
		date: "2026-03-14",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Challenge",
			},
		],
		date: "2026-03-15",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Classroom",
			},
		],
		date: "2026-03-16",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Historian",
			},
		],
		date: "2026-03-17",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Typically",
			},
		],
		date: "2026-03-18",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Accompany",
			},
		],
		date: "2026-03-19",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Certainly",
			},
		],
		date: "2026-03-20",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Beginning",
			},
		],
		date: "2026-03-21",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Secondary",
			},
		],
		date: "2026-03-22",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Religious",
			},
		],
		date: "2026-03-23",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Transform",
			},
		],
		date: "2026-03-24",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Financial",
			},
		],
		date: "2026-03-25",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "X",
						points: 8,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Excellent",
			},
		],
		date: "2026-03-26",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Currently",
			},
		],
		date: "2026-03-27",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Formation",
			},
		],
		date: "2026-03-28",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 1,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Necessary",
			},
		],
		date: "2026-03-29",
	},
	{
		RandomNine: [
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Chocolate",
			},
		],
		date: "2026-03-30",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Increases",
			},
		],
		date: "2026-03-31",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Resources",
			},
		],
		date: "2026-04-01",
	},
	{
		RandomNine: [
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Education",
			},
		],
		date: "2026-04-02",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Interests",
			},
		],
		date: "2026-04-03",
	},
	{
		RandomNine: [
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Questions",
			},
		],
		date: "2026-04-04",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Attribute",
			},
		],
		date: "2026-04-05",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Guarantee",
			},
		],
		date: "2026-04-06",
	},
	{
		RandomNine: [
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Evolution",
			},
		],
		date: "2026-04-07",
	},
	{
		RandomNine: [
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 2,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
				],
				word: "Continued",
			},
		],
		date: "2026-04-08",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Functions",
			},
		],
		date: "2026-04-09",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Awareness",
			},
		],
		date: "2026-04-10",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Contained",
			},
		],
		date: "2026-04-11",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Technique",
			},
		],
		date: "2026-04-12",
	},
	{
		RandomNine: [
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
				],
				word: "Confident",
			},
		],
		date: "2026-04-13",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Reinforce",
			},
		],
		date: "2026-04-14",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 2,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Christian",
			},
		],
		date: "2026-04-15",
	},
	{
		RandomNine: [
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "V",
						points: 4,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Defensive",
			},
		],
		date: "2026-04-16",
	},
	{
		RandomNine: [
			{
				char: "X",
				points: 8,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
				],
				word: "Discussed",
			},
		],
		date: "2026-04-17",
	},
	{
		RandomNine: [
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "W",
						points: 4,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Following",
			},
		],
		date: "2026-04-18",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 2,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Implement",
			},
		],
		date: "2026-04-19",
	},
	{
		RandomNine: [
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 1,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Economist",
			},
		],
		date: "2026-04-20",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Physician",
			},
		],
		date: "2026-04-21",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Selection",
			},
		],
		date: "2026-04-22",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "H",
						points: 4,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Authority",
			},
		],
		date: "2026-04-23",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "B",
						points: 3,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Brilliant",
			},
		],
		date: "2026-04-24",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Discourse",
			},
		],
		date: "2026-04-25",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Territory",
			},
		],
		date: "2026-04-26",
	},
	{
		RandomNine: [
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Remaining",
			},
		],
		date: "2026-04-27",
	},
	{
		RandomNine: [
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Attention",
			},
		],
		date: "2026-04-28",
	},
	{
		RandomNine: [
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 2,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 1,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
				],
				word: "Direction",
			},
		],
		date: "2026-04-29",
	},
	{
		RandomNine: [
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
				],
				word: "Gradually",
			},
		],
		date: "2026-04-30",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Countries",
			},
		],
		date: "2026-05-01",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Spiritual",
			},
		],
		date: "2026-05-02",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
				],
				word: "Intensity",
			},
		],
		date: "2026-05-03",
	},
	{
		RandomNine: [
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
				],
				word: "Assistant",
			},
		],
		date: "2026-05-04",
	},
	{
		RandomNine: [
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 1,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Operating",
			},
		],
		date: "2026-05-05",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Insurance",
			},
		],
		date: "2026-05-06",
	},
	{
		RandomNine: [
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 2,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "H",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 1,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
				],
				word: "Precisely",
			},
		],
		date: "2026-05-07",
	},
	{
		RandomNine: [
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 1,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "M",
				points: 3,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Standards",
			},
		],
		date: "2026-05-08",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 2,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "C",
				points: 3,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
				],
				word: "Commander",
			},
		],
		date: "2026-05-09",
	},
	{
		RandomNine: [
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "L",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 2,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
				],
				word: "Candidate",
			},
		],
		date: "2026-05-10",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "J",
				points: 8,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 2,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
			{
				char: "U",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
				],
				word: "Passenger",
			},
		],
		date: "2026-05-11",
	},
	{
		RandomNine: [
			{
				char: "Q",
				points: 10,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Terrorism",
			},
		],
		date: "2026-05-12",
	},
	{
		RandomNine: [
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "D",
				points: 2,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "P",
				points: 3,
				type: 0,
			},
			{
				char: "G",
				points: 2,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 2,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "Q",
						points: 10,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
				],
				word: "Equipment",
			},
		],
		date: "2026-05-13",
	},
	{
		RandomNine: [
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "V",
				points: 4,
				type: 2,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 0,
			},
			{
				char: "F",
				points: 4,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "W",
				points: 4,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "M",
						points: 3,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
				],
				word: "Companies",
			},
		],
		date: "2026-05-14",
	},
	{
		RandomNine: [
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "Z",
				points: 10,
				type: 0,
			},
			{
				char: "S",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
			{
				char: "M",
				points: 3,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "K",
				points: 5,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "D",
						points: 2,
						type: 0,
					},
					{
						char: "U",
						points: 1,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "L",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
					{
						char: "G",
						points: 2,
						type: 0,
					},
					{
						char: "N",
						points: 1,
						type: 0,
					},
					{
						char: "I",
						points: 1,
						type: 0,
					},
				],
				word: "Including",
			},
		],
		date: "2026-05-15",
	},
	{
		RandomNine: [
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 0,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "E",
				points: 1,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "R",
				points: 1,
				type: 1,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "A",
						points: 1,
						type: 0,
					},
					{
						char: "T",
						points: 1,
						type: 0,
					},
					{
						char: "Y",
						points: 4,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "C",
						points: 3,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Secretary",
			},
		],
		date: "2026-05-16",
	},
	{
		RandomNine: [
			{
				char: "B",
				points: 3,
				type: 1,
			},
			{
				char: "I",
				points: 1,
				type: 0,
			},
			{
				char: "X",
				points: 8,
				type: 0,
			},
			{
				char: "O",
				points: 1,
				type: 0,
			},
			{
				char: "Y",
				points: 4,
				type: 2,
			},
			{
				char: "T",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "N",
				points: 1,
				type: 0,
			},
			{
				char: "A",
				points: 1,
				type: 0,
			},
		],
		NineWord: [
			{
				arr: [
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
					{
						char: "E",
						points: 1,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "P",
						points: 3,
						type: 0,
					},
					{
						char: "F",
						points: 4,
						type: 0,
					},
					{
						char: "O",
						points: 1,
						type: 0,
					},
					{
						char: "S",
						points: 1,
						type: 0,
					},
					{
						char: "R",
						points: 1,
						type: 0,
					},
				],
				word: "Professor",
			},
		],
		date: "2026-05-17",
	},
];

const nineLetterWords = [
	"Different",
	"Important",
	"Following",
	"Political",
	"Therefore",
	"Something",
	"Education",
	"Necessary",
	"Knowledge",
	"According",
	"Sometimes",
	"Community",
	"Including",
	"Structure",
	"Available",
	"Treatment",
	"Character",
	"President",
	"Countries",
	"Generally",
	"Influence",
	"Situation",
	"Described",
	"Difficult",
	"Attention",
	"Continued",
	"Questions",
	"Committee",
	"Developed",
	"Religious",
	"Authority",
	"Condition",
	"Beginning",
	"Relations",
	"Published",
	"Christian",
	"Direction",
	"Operation",
	"Effective",
	"Resources",
	"Certainly",
	"Principle",
	"Materials",
	"Potential",
	"Presented",
	"Statement",
	"Financial",
	"Existence",
	"Reference",
	"Interests",
	"Mentioned",
	"Concerned",
	"Agreement",
	"Processes",
	"Functions",
	"Discussed",
	"September",
	"Essential",
	"Otherwise",
	"Determine",
	"Companies",
	"Suggested",
	"Expressed",
	"Principal",
	"Practical",
	"Beautiful",
	"Formation",
	"Procedure",
	"Secretary",
	"Equipment",
	"Advantage",
	"Technical",
	"Spiritual",
	"Standards",
	"Professor",
	"Tradition",
	"Frequency",
	"Institute",
	"Decisions",
	"Performed",
	"Indicated",
	"Represent",
	"Explained",
	"Immediate",
	"Connected",
	"Secondary",
	"Americans",
	"Insurance",
	"Carefully",
	"Selection",
	"Employees",
	"Cambridge",
	"Appointed",
	"Contained",
	"Increases",
	"Supported",
	"Movements",
	"Establish",
	"Extremely",
	"Accompany",
	"Architect",
	"Adventure",
	"Apartment",
	"Admission",
	"Awareness",
	"Afternoon",
	"Associate",
	"Attribute",
	"Assistant",
	"Boyfriend",
	"Breakfast",
	"Basically",
	"Brilliant",
	"Criticize",
	"Childhood",
	"Celebrity",
	"Commander",
	"Chocolate",
	"Criticism",
	"Complaint",
	"Cognitive",
	"Component",
	"Confident",
	"Challenge",
	"Confusion",
	"Currently",
	"Candidate",
	"Celebrate",
	"Corporate",
	"Construct",
	"Classroom",
	"Calculate",
	"Cigarette",
	"Colleague",
	"Consensus",
	"Container",
	"Counselor",
	"Discovery",
	"Diversity",
	"Defensive",
	"Depending",
	"Defendant",
	"Disappear",
	"Dependent",
	"Discourse",
	"Dimension",
	"Desperate",
	"Dangerous",
	"Emphasize",
	"Executive",
	"Everybody",
	"Expensive",
	"Exception",
	"Extensive",
	"Expansion",
	"Excellent",
	"Efficient",
	"Emergency",
	"Extension",
	"Economics",
	"Elsewhere",
	"Economist",
	"Evolution",
	"Encourage",
	"Eliminate",
	"Emotional",
	"Encounter",
	"Framework",
	"Furniture",
	"Gradually",
	"Gentleman",
	"Guideline",
	"Guarantee",
	"Highlight",
	"Household",
	"Historian",
	"Implement",
	"Interview",
	"Infection",
	"Incentive",
	"Increased",
	"Intensity",
	"Introduce",
	"Initially",
	"Inflation",
	"Interpret",
	"Intention",
	"Objective",
	"Offensive",
	"Obviously",
	"Operating",
	"Ourselves",
	"Perfectly",
	"Physician",
	"Pregnancy",
	"Primarily",
	"Publisher",
	"Precisely",
	"Provision",
	"Prominent",
	"Permanent",
	"Passenger",
	"Personnel",
	"Pollution",
	"Recognize",
	"Recommend",
	"Reinforce",
	"Recording",
	"Regularly",
	"Remaining",
	"Regarding",
	"Reduction",
	"Somewhere",
	"Similarly",
	"Stability",
	"Supporter",
	"Substance",
	"Strategic",
	"Surprised",
	"Seriously",
	"Sensitive",
	"Scientist",
	"Technique",
	"Typically",
	"Temporary",
	"Testimony",
	"Transform",
	"Telephone",
	"Telescope",
	"Territory",
	"Terrorism",
	"Translate",
];

export function genDailyShi() {
	let DailyLetters = [];

	var rand = Shuffle(nineLetterWords);

	for (let i = 0; i < rand.length; i++) {
		DailyLetters.push({
			RandomNine: getRandomNine(),
			NineWord: [createSpecialNineLetterWord(rand[i])],
			date: theCurrDate(new Date("2025-09-29"), i),
		});
	}
	console.log(DailyLetters);
}

function theCurrDate(date, i) {
	var tomorrow = date;
	tomorrow.setDate(tomorrow.getDate() + i);
	return formatDate(tomorrow);
}

function formatDate(date) {
	var d = new Date(date),
		month = "" + (d.getMonth() + 1),
		day = "" + d.getDate(),
		year = d.getFullYear();

	if (month.length < 2) month = "0" + month;
	if (day.length < 2) day = "0" + day;

	return [year, month, day].join("-");
}

function createSpecialNineLetterWord(word) {
	let NineWord = word;
	let letterArray = [];
	let allLetters = getLetters();

	for (let i = 0; i < 9; i++) {
		let index = allLetters
			.map((e) => e.char)
			.indexOf(NineWord.charAt(i).toUpperCase());
		var letter = allLetters[index];

		letterArray.push({
			char: letter.char,
			points: letter.points,
			type: letter.type,
		});
	}

	return { arr: Shuffle(letterArray), word: NineWord };
}

export function createNineLetterWord() {
	let constRange = Math.floor(Math.random() * 232);

	var letterArray = [];
	let NineWord = nineLetterWords[constRange];
	let allLetters = getLetters();

	for (let i = 0; i < 9; i++) {
		let index = allLetters
			.map((e) => e.char)
			.indexOf(NineWord.charAt(i).toUpperCase());
		var letter = allLetters[index];

		letterArray.push({
			char: letter.char,
			points: letter.points,
			type: letter.type,
		});
	}
	return { arr: letterArray, word: NineWord };
}

export function getTodaysLetters(date) {
	let todaysLetters = "";
	for (let i = 0; i < words.length; i++) {
		if (words[i].date === date) {
			todaysLetters = words[i];
		}
	}
	let lettersObj = {
		RandomNine: todaysLetters.RandomNine,
		NineWordLetters: todaysLetters.NineWord[0].arr,
		NineWord: todaysLetters.NineWord[0].word,
		date: todaysLetters.date,
	};
	return lettersObj;
}

export function getLetters() {
	const fullarray = vowels.concat(constants);
	return fullarray;
}

export function getRandomVowel() {
	var weight = [0.3, 0.2, 0.2, 0.2, 0.1];

	var weighed_list = generateWeighedList(vowels, weight);

	var random_num = rand(0, weighed_list.length - 1);

	return weighed_list[random_num];
}

var rand = function (min, max) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
};

var generateWeighedList = function (list, weight) {
	var weighed_list = [];
	// Loop over weights
	for (var i = 0; i < weight.length; i++) {
		var multiples = weight[i] * 100;

		// Loop over the list of items
		for (var j = 0; j < multiples; j++) {
			weighed_list.push(list[i]);
		}
	}

	return weighed_list;
};

export function getRandomConstant() {
	var weight = [
		0.03872, 0.063388, 0.051844, 0.036121, 0.095773, 0.075351, 0.087509,
		0.072893, 0.048129, 0.084544, 0.049671, 0.042705, 0.048034, 0.029016,
		0.028074, 0.030899, 0.035779, 0.019965, 0.019962, 0.020901, 0.020722,
	];

	var weighed_list = generateWeighedList(constants, weight);

	var random_num = rand(0, weighed_list.length - 1);

	return weighed_list[random_num];
}

export function getRandomNine() {
	let RandomArr = [];

	for (let i = 0; i < 9; i++) {
		if (i % 3 === 0) {
			RandomArr.push(getRandomVowel());
		} else {
			RandomArr.push(getRandomConstant());
		}
	}

	let RandomIndexSilver, RandomIndexGold;

	// Generate the first random number between 0 and 8
	RandomIndexSilver = Math.floor(Math.random() * 9);

	// Generate the second random number, ensuring it's different from the first
	do {
		RandomIndexGold = Math.floor(Math.random() * 9);
	} while (RandomIndexGold === RandomIndexSilver);

	RandomArr[RandomIndexSilver] = {
		char: RandomArr[RandomIndexSilver].char,
		points: RandomArr[RandomIndexSilver].points,
		type: 1,
	};

	RandomArr[RandomIndexGold] = {
		char: RandomArr[RandomIndexGold].char,
		points: RandomArr[RandomIndexGold].points,
		type: 2,
	};

	return Shuffle(RandomArr);
}

function Shuffle(array) {
	let currentIndex = array.length,
		randomIndex;

	// While there remain elements to shuffle.
	while (currentIndex > 0) {
		// Pick a remaining element.
		randomIndex = Math.floor(Math.random() * currentIndex);
		currentIndex--;

		// And swap it with the current element.
		[array[currentIndex], array[randomIndex]] = [
			array[randomIndex],
			array[currentIndex],
		];
	}

	return array;
}
